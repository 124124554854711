/**
 * Theme global.js
 */

function init() {
    'use strict';

    //
}

document.addEventListener('DOMContentLoaded', init);

(function ($) {
    $(document).ready(function () {

        $('.hamburger--collapse').on('click', function (e) {
            $(this).toggleClass('is-active');
            $('nav.mobile').slideToggle(300);
            $('body').toggleClass('mobile-closed mobile-open');
        });
        if ($('nav.mobile .menu > .menu-item-has-children').length) {
            $('nav.mobile .menu-item-has-children > a').append('<i class="plus"></i>');
        }
        $('nav.mobile i').on('click', function (e) {
            e.preventDefault();
            if (e.target === $(this)[0]) {
                $(this).toggleClass('open');
                $(this).parent().siblings('.sub-menu').slideToggle(150);
            }
        });

        //Calculate white background height for submenu
        let sub_menu_height = $('nav.desktop .menu .menu-item-has-children > .sub-menu > li > .sub-menu').outerHeight();
        let sub_menu_bg_height = sub_menu_height + 82;
        $('nav.desktop .menu .menu-item-has-children > .sub-menu').css('height', sub_menu_bg_height);

        $('.grid-item').finnishHyphenator();

        $('.cta-banner .link').on('click', function () {
            $(this).siblings('.infotext').slideToggle(100);
        });

        if ($('.feedback-slider'.length)) {
            $('.feedback-slider .slider').slick({
                slidesToShow: 3,
                slidesToScroll: 3,
                dots: true,
                autoplaySpeed: 5000,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 520,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        }

        if ($('.error404').length) {
            let window_height = $( window ).height();
            let campaign_height = 0;
            if ($('.campaign-banner').length) {
                campaign_height = $('.campaign-banner').outerHeight();
            }
            let header_footer_campaign_height = $('header').outerHeight() + $('footer').outerHeight() + campaign_height;
            let container_height = window_height - header_footer_campaign_height;
            $('.error404 section > .container').css('height', container_height);
        }
    });

    //Category select with anchors
    $('.categories-cloud select').on('change', function () {
        window.location = $(this).val();
    });

    //FAQ block
    $('.toggle').click(function (e) {
        $(this).parent().siblings().find('.toggle').removeClass('active');
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
        } else {
            $(this).addClass('active');
        }
        if ($(this).next().hasClass('show')) {
            $(this).next().removeClass('show');
            $(this).next().slideUp(150);
        } else {
            $(this).parent().siblings().find('.answer').removeClass('show');
            $(this).parent().siblings().find('.answer').slideUp(350);
            $(this).next().toggleClass('show');
            $(this).next().slideToggle(150);
        }
    });

    $('header button.search').on('click', function () {
       $('header .search-form').slideToggle(200).css('display', 'flex');
    });

})(jQuery);

